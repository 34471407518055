<template>
  <div style="width: 100%;height:100%">
    <div class="school" v-if="$store.state.pc">
      <div class="head">
        <h1 :style="this.$route.path == '/nhwx' || this.$route.path == '/nhwx/'
      ? 'display: none;'
      : ''
      "></h1>

        <div style="display: none">{{ themeOther }}</div>
        <div class="block" v-if="bannerList && bannerList.length >= 2">
          <el-carousel height="100%">
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
              <img :src="item.url" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="banner" v-else>
          <img :src="bannerList[0].url" alt="" />
        </div>
      </div>
      <div class="content">
        <p><span>项目列表</span></p>
        <div class="content_text">
          <div class="left">
            <div class="content_title" v-for="(item, index) in themeData" :key="index" :class="[
      currentIndex == index
        ? 'content_title_current'
        : 'content_title',
    ]" @click="tabTitle(index, item)">
              {{ item.name }}
            </div>
          </div>
          <div class="right" v-if="themeData && themeData[currentIndex]">
            <div class="ducation" v-for="(item, index) in themeData[currentIndex].children" :key="index"
              @mouseover="over(index)" @mouseout="out(index)">
              <div class="education_left">
                <div class="child1">
                  <span class="icon iconfont">&#xe6c2;</span>
                </div>
              </div>
              <div class="education_center">
                <span>{{ item.name }}</span>
                <span>{{ item.explain }}</span>
              </div>
              <div class="education_right" @click="gocontent(item)">
                <span>查看详情</span>
              </div>
              <div class="op_content" v-if="navList && navList[index]">
                <p v-for="(items, indexs) in navList[index].children" :key="indexs"
                  @click="check(items, indexs, index)">
                  <span>{{ items.name }}</span><span>查看详情</span>
                </p>
              </div>
            </div>

            <div class="pages">
              <el-pagination v-show="yemashow" background layout="prev, pager, next" :page-size="4"
                :total="themeData[currentIndex].children.length">
              </el-pagination>
            </div>
          </div>
        </div>
        <basecopyRight></basecopyRight>
      </div>
    </div>

    <div class="school" v-else>
      <div class="head">
        <div class="block" v-if="bannerList.length >= 2">
          <el-carousel height="200px">
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
              <img :src="item.url" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="banner" v-else>
          <img :src="bannerList[0].url" alt="" />
        </div>
      </div>
      <div class="content">
        <div class="content_text">
          <van-collapse v-model="activeName" accordion class="dym_collapse">
            <van-collapse-item class="collapse_item" :title="item.name" :name="index + 1"
              v-for="(item, index) in themeData" :key="index">
              <div class="conteny_data" v-for="(item1, index1) in item.children" :key="index1">
                <p>{{ item1.name }}</p>
                <p>{{ item1.explain }}</p>
                <div class="look" @click="gocontent(item1)">查看详情</div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>
      <div class="foor">
        <basecopyRight></basecopyRight>
      </div>
    </div>
  </div>
</template>

<script>
import {
  zhuanlan_index,
  banner_list,
  index_list,
  twice_index,
} from "../../api/api";
import basecopyRight from "../../components/Public/BasecopyRight/basecopyRight.vue";
export default {
  components: { basecopyRight },
  data() {
    return {
      themeOther: JSON.parse(localStorage.getItem("themeOther")),
      themeData: JSON.parse(localStorage.getItem("themeData")),
      activeName: "",
      bannerList: [{ url: "" }],
      total: 8,
      currentIndex: 0,
      navList: [],
      // 项目列表数据

      yemashow: false,
    };
  },
  created() {
    console.log(window.location.href.indexOf('jxcg') > -1);
    if (localStorage.getItem("urlL")) {
      window.setTimeout(() => {

        if (window.location.href.indexOf('szyx') > -1) {
          this.$router.push({
            path: `/${localStorage.getItem(
              "urlL"
            )}/four?project_id=51&name=老年人智能运用技术`,
          });
        }
        if (window.location.href.indexOf('jxcg') > -1) {
          this.$router.push({
            path: `/${localStorage.getItem(
              "urlL"
            )}/four?project_id=59&name=教学成果奖`,
          });
        }
        if (window.location.href.indexOf('wxjx') > -1) {
          this.$router.push({
            path: `/${localStorage.getItem(
              "urlL"
            )}/four?project_id=61&name=教学成果奖`,
          });
        }
      }, 1000);
    } else {
      console.log(window);
      if (window.location.href.indexOf('szyx') > -1) {
        this.$router.push({
          path: `/${localStorage.getItem(
            "urlL"
          )}/four?project_id=51&name=老年人智能运用技术`,
        });
      }
      if (window.location.href.indexOf('jxcg') > -1) {
        this.$router.push({
          path: `/${localStorage.getItem(
            "urlL"
          )}/four?project_id=59&name=教学成果奖`,
        });
      }
      if (window.location.href.indexOf('wxjx') > -1) {
        this.$router.push({
          path: `/${localStorage.getItem(
            "urlL"
          )}/four?project_id=61&name=教学成果奖`,
        });
      }
    }


    this.getbanner();
    console.log(2222);
    // 主题色
    // this.color();
    this.getData();
    if (this.$route.path == "/nhwx") {
      this.yemashow = false;
    } else {
      this.yemashow = true;
    }
  },
  mounted() {
    console.log(this.$route.path);

  },
  methods: {
    // color() {
    //   if (localStorage.getItem('themeOther') && localStorage.getItem('themeOther').color) {
    //     document
    //       .getElementsByTagName("body")[0]
    //       .style.setProperty("--test", localStorage.getItem('themeOther').color);
    //   }

    // },
    check(item, index, indexs) {
      console.log(item, index);
      document.title = this.navList[indexs].name;

      console.log(this.navList);
      this.$router.push({
        path: `/${localStorage.getItem("urlL")}/contentfour`,
        query: {
          project_id: this.navList[indexs].id,
          modular_id: item.id,
          name: item.name,
        },
      });
    },
    getData(id) {
      console.log(this.themeData[0].id);

      twice_index({ project_id: id ? id : this.themeData[0].id }).then(
        (res) => {
          console.log(res.data.data);
          this.navList = res.data.data;
        }
      );
    },
    gocontent(value) {
      console.log(value.id);
      document.title = value.name;

      this.$store.commit("domTitle", value.name);
      this.$router.push({
        path: `/${localStorage.getItem("urlL")}/four`,
        query: { project_id: value.id, name: value.name },
      });
      this.$store.state.name = value.name;
    },
    tabTitle(index, item) {
      this.currentIndex = index;
      console.log(item.id);
      this.getData(item.id);
      // 调接口
    },

    // 获取banner
    getbanner() {
      banner_list({ zl_url: localStorage.getItem("urlL") }).then((res) => {
        this.bannerList = res.data.data;
      });
    },
    // 动画
    over(index) {
      let ducation = document.getElementsByClassName("ducation");
      ducation.forEach((item1, index1) => {
        item1.style.zIndex = 1;
      });
      ducation[index].style.zIndex = 99;
    },
    out(index) {
      let ducation = document.getElementsByClassName("ducation");
      ducation.forEach((item1, index1) => {
        item1.style.zIndex = 1;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/default/default.scss";

.pc {
  .school {
    width: 100%;
    height: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    .head {
      position: relative;
      width: 100%;
      height: 100% !important;

      .block {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;

        /deep/.el-carousel {
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          -webkit-user-drag: none;
        }
      }

      .banner {
        img {
          width: 100%;
          height: 100%;
        }

        width: 100%;
        height: 100%;
      }

      color: white;
      width: 100%;
      height: 100%;
      background-size: 100%;

      h1 {
        position: absolute;
        top: 15%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }
    }

    .content {
      position: absolute;

      left: 50%;
      top: 50%;
      transform: translate(-50%, -35%);
      width: 1400px;
      min-height: 480px;

      p {
        span {
          padding-left: 15px;
          border-left: 3px solid $brown;
        }
      }

      .content_text {
        width: 1400px;
        min-width: 1400px;
        display: flex;
        justify-content: space-between;

        .left {
          min-height: 480px;
          width: 302px;
          max-width: 302px;

          .content_title {
            &:hover {
              cursor: pointer;
            }

            width: 100%;
            height: 60px;
            color: #999999;
            border-radius: 5px;

            background: white;
            line-height: 60px;
            text-align: center;
            margin-bottom: 10px;
            font-weight: bold;
          }

          .content_title_current {
            width: 100%;
            height: 60px;
            margin-bottom: 10px;
            color: white;
            border-radius: 5px;
            background: $brown;
            line-height: 60px;
            text-align: center;
            font-weight: bold;
          }
        }

        .right {
          background: rgba(255, 255, 255, 0.823);
          min-height: 480px;
          width: 1084px;
          max-width: 1084px;
          box-sizing: border-box;
          padding: 20px 20px;
          position: relative;

          .pages {
            display: inline-block;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);

            ::v-deep .el-pagination.is-background {
              display: inline-block;

              .el-pager li:not(.disabled).active {
                background-color: $brown;
              }
            }
          }

          .ducation {
            border-radius: 10px;
            box-sizing: border-box;
            position: absolute;
            width: 100%;
            margin-bottom: 15px;
            max-height: 100px;
            max-width: 1084px;
            height: 92px;
            background: #eef5ff;
            display: flex;
            justify-content: space-between;
            position: relative;
            transition: 1s all;

            &:hover {
              background: $brown;
              color: white;
            }

            &:hover .op_content {
              display: block;
              opacity: 0;
              color: black;
              z-index: 99;
              animation: mymove 1.5s forwards;
            }

            &:hover .education_center {
              span {
                &:nth-child(1) {
                  margin-top: 20px;
                  width: 100%;
                  line-height: 30px;
                }

                &:nth-child(2) {
                  line-height: 30px;
                  padding-left: 5px;
                }
              }
            }

            .op_content {
              position: absolute;
              display: none;
              padding: 0;
              z-index: 2;
              border-radius: 0 0 10px 10px;
              overflow: hidden;
              width: 100%;
              position: absolute;
              background: #eef5ff;

              span {
                border: none;
                transition: 1s all;

                &:nth-child(2) {
                  float: right;
                  margin-right: 200px;
                }
              }

              p {
                box-sizing: border-box;
                height: 54px;
                width: 100%;
                line-height: 54px;
                padding-left: 100px;
                transition: 0.3s all;
                margin: 0;

                &:hover {
                  background: #d7d7d7;
                  cursor: pointer;

                  & span:nth-child(2) {
                    color: $brown;
                  }
                }
              }
            }

            .education_left {
              position: absolute;
              left: 0%;
              z-index: 20;
              padding-top: 15px;
              padding-left: 30px;
              width: 5%;
              height: 100px;
              text-align: center;
              font-size: 14px;
              color: white;
              background-position: center;
              position: relative;

              .child1 {
                text-align: center;
                color: $brown;
                font-size: 40px;
                width: 30px;
                height: 30px;

                span {
                  display: inline-block;
                  padding: 15px;
                  background: white;
                  border-radius: 50%;
                  font-size: 30px;
                }
              }
            }

            .education_center {
              position: absolute;
              left: 5%;
              z-index: 3;
              width: 72%;
              height: 100%;

              span {
                font-size: 18px;
                margin-left: 80px;

                &:nth-child(1) {
                  margin-top: 14px;
                  display: inline-block;
                  width: 70%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                &:nth-child(2) {
                  display: inline-block;
                  text-align: center;
                  font-size: 14px;
                  text-align: right;
                }
              }
            }

            .education_right {
              position: absolute;
              left: 78%;
              z-index: 3;
              width: 20%;
              color: $brown;
              line-height: 100px;
              text-align: center;

              &:hover {
                cursor: pointer;
              }

              span {
                background: #eef5ff;
                padding: 10px 15px;
                border-radius: 18px;
              }
            }
          }
        }
      }
    }
  }
}

.mob {
  width: 100%;

  .school {
    width: 100%;

    .head {
      height: 18.75rem;
      width: 100%;
      position: relative;

      .banner {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .block {
        img {
          height: 100%;
          width: 100%;

          /deep/.el-carousel {
            height: 18.75rem;

            .el-carousel__container {
              height: 18.75rem;

              .el-carousel__item {
                height: 18.75rem;
              }

              .el-carousel__arrow {
                height: 18.75rem;
              }
            }
          }
        }
      }

      h1 {
        margin: 0;
        color: white;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 88;
        font-size: 2.25rem;
        transform: translate(-50%, -50%);
      }
    }

    .content {
      margin-top: 20px;
      width: 100%;
      position: relative;

      /deep/.van-collapse-item__content {
        background: #f5f5f5;
      }

      .content_text {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;

        .dym_collapse {
          /deep/ .van-collapse-item__title--expanded {
            background: $brown;
            border-radius: 5px;
          }

          /deep/ .collapse_item {
            .van-cell__title {
              height: 5rem;
              font-size: 1.875rem;
              line-height: 5rem;

              .van-icon {
                line-height: 5rem !important;
              }

              .van-cell__right-icon,
              .van-icon {
                line-height: 5rem;
                margin-top: 20px;
              }
            }
          }

          margin-bottom: 20px;
          height: 6.25rem;

          /deep/ .collapse_item {
            margin-top: 20px;
          }

          /deep/ .van-collapse-item__content {
            background-color: #f1f1f1;

            /deep/ .van-collapse-item {
              margin-bottom: 20px;
            }

            .van-collapse-item__title {
              height: 100px;
            }

            .conteny_data {
              height: 12.5rem;
              position: relative;
              padding: 20px 15px;
              border: 1px solid $brown;
              border-radius: 5px;
              margin-bottom: 10px;
              background: white;

              p {
                &:nth-child(1) {
                  color: #333333;
                  font-size: 1.4rem;
                  margin-top: 0;
                }

                &:nth-child(2) {
                  color: #999999;
                  font-size: 1rem;
                }
              }

              .look {
                border-radius: 2rem;
                width: 50%;
                position: absolute;
                bottom: 10px;
                left: 50%;
                height: 4rem;
                text-align: center;
                line-height: 4rem;
                transform: translateX(-50%);
                background: #f2eee6;
                color: $brown;
                font-size: 1.625rem;
              }
            }
          }
        }
      }
    }

    .foor {
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@keyframes mymove {
  from {
    opacity: 0;
    margin-top: 0px;
    z-index: 2;
  }

  to {
    opacity: 1;
    margin-top: 92px;
    z-index: 1;
  }
}
</style>
