import { render, staticRenderFns } from "./schoolProjectListfour.vue?vue&type=template&id=308be2f5&scoped=true"
import script from "./schoolProjectListfour.vue?vue&type=script&lang=js"
export * from "./schoolProjectListfour.vue?vue&type=script&lang=js"
import style0 from "./schoolProjectListfour.vue?vue&type=style&index=0&id=308be2f5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "308be2f5",
  null
  
)

export default component.exports